import { gql } from "@apollo/client";

export const GET_VENDORS = gql`
  query GetVendors {
    getVendors {
      _id
      tradingName
      imageUrl
      businessName
      contactPhone
      contactEmail
      isDisabled
    }
  }
`;

export const GET_BASIC_ACCOUNT_DETAILS = gql`
  query GetBasicAccountDetails {
    getVendors {
      _id
      tradingName
      isDisabled
    }
  }
`;

//! intention typo to match with backend API
export const GET_REFUND_ORDERS = gql`
  query GetAllRefunds {
    getAllRefunds {
      _id
      orderNumber
      orderId
      patronId
      patron {
        _id
        name
        email
        phoneNumber
        isDeleted
      }
      amountRequested
      amountRefunded
      refundStatus
      issueDescription {
        refundReason
        reportorComments
        reviewerComments
      }
      openDate
      closeDate
      transactionId
      requestedRefundItems {
        id
        price
        quantity
        refundQuantity
      }
      finalRefundItems {
        id
        price
        quantity
        refundQuantity
      }
    }
  }
`;

export const GET_REFUND_BY_ID = gql`
  query getRefund($id: String!) {
    getRefund(id: $id) {
      _id
      orderNumber
      orderId
      patronId
      patron {
        _id
        name
        email
        phoneNumber
        isDeleted
      }
      amountRequested
      amountRefunded
      refundStatus
      issueDescription {
        refundReason
        reportorComments
        reviewerComments
      }
      openDate
      closeDate
      transactionId
      requestedRefundItems {
        id
        price
        quantity
        refundQuantity
      }
      finalRefundItems {
        id
        price
        quantity
        refundQuantity
      }
    }
  }
`;

export const GET_VENUES = gql`
  query getVenues {
    getVenues {
      id
      name
      address {
        country
        state
        addressLine1
        addressLine2
        postlCode
      }
      venueType
      sections {
        label
        id
        isDeleted
      }
      isDisabled
    }
  }
`;

export const GET_VENUE = gql`
  query getVenue($id: String!) {
    getVenue(id: $id) {
      id
      name
      venueCode
      businessTaxNumber 
      accountID
      venueType
      address {
        country
        state
        addressLine1
        addressLine2
        postlCode
      }
    
      sections {
        label
        id
        isClickAndCollect
        serviceMethods
        cncNavigationImageUrl
        isDeleted
      }
      collectionPoints {
        id
        label
        serviceableSectionsIds
        cpNavigationImageUrl
        isDeleted
      }
      deliveryAreas {
        id
        label
        serviceableSectionsIds
        daNavigationImageUrl
        isDeleted
      }
      venueImages {
        venueMapImageUrl
        ttsBrandingImageUrl
        cncBrandingImageUrl
        vendorListImageUrl
        patronNavigationImageUrl
      }
      isDisabled
      venueCurrency {
        _id
        name
        symbol
        stripeCode
      }
      venueSettings {
        minimumOrderPrice
        fixedServiceCharge
        venueCurrency
      }
      venueMapImageUrl
      ttsBrandingImageUrl
      cncBrandingImageUrl
      vendorListImageUrl
      patronNavigationImageUrl
      printReceiptImageUrl
      minimumOrderPrice
      fixedServiceCharge
      fixedVendorDeliveryCharge

      orderSettings{
        capturePaymentByOrderStatus
      }
    }
  }
`;

export const GET_VENDOR = gql`
  query getVendor($id: String!) {
    getVendor(id: $id) {
      _id
      businessName
      bankAccount
      BSB
      tradingName
      imageUrl
      abn
      contactPhone
      contactEmail
      isDisabled
      stallID
      venueID
      sectionIDs
    }
  }
`;

export const GET_RUNNERS = gql`
  query getRunners {
    getRunners {
      _id
      name
      email
      phoneNumber
      address
      tfn
      bankAccount
      superAnnuationFund
      venueID
      sectionIDs
      isDisabled
    }
  }
`;

export const GET_RUNNERS_FOR_ASSIGNMENT = gql`
  query GetAssignableRunners($input: AssignableRunnerInput) {
    getAssignableRunners(input: $input) {
      runner {
        _id
        name
        phoneNumber
      }
      count
    }
  }
`;

export const GET_RUNNER = gql`
  query getRunner($id: String!) {
    getRunner(id: $id) {
      _id
      name
      email
      phoneNumber
      address
      tfn
      bankAccount
      superAnnuationFund
      venueID
      sectionIDs
      isDisabled
      BSB
    }
  }
`;

export const GET_ADMINS = gql`
  query getAdmins {
    getAdmins {
      _id
      name
      email
      cognitoID
    }
  }
`;

export const GET_ADMIN = gql`
  query getAdmin($id: String!) {
    getAdmin(id: $id) {
      _id
      name
      email
      cognitoID
    }
  }
`;

export const GET_CHARGES = gql`
  query Charges {
    getCharges {
      markupCharge
      seatDeliveryCharge
      collectionPointDeliveryCharge
      serviceCharge
    }
  }
`;

export const GET_CURRENCIES = gql`
  query GetCurrencies {
    getCurrencies {
      _id
      name
      stripeCode
      symbol
    }
  }
`;

export const GET_USER_INFO = gql`
  query getUserInfo {
    getUserInfo {
      username
      groups
      admin {
        _id
        name
        email
        cognitoID
      }
    }
  }
`;

export const GET_ALL_PATRON_ORDERS = gql`
  query getAllPatronOrders($payload: PatronOrderQueryParams) {
    getAllPatronOrders(payload: $payload) {
      pagination {
        total
        limit
      }
      data {
        _id
        orderStatus
        isClickAndCollectEnabled
        serviceMethod
        orderNumber
        createdAt
        updatedAt
        patron {
          _id
          name
          phoneNumber
        }
        vendorOrders {
          _id
          history {
            status
            createdAt
            userType
            message
          }
          patronOrderID
          orderNumber
          orderStatus
          runner {
            _id
            name
            phoneNumber
          }
          vendor {
            _id
            tradingName
          }
          isOrderUpdated
          items {
            _id
            count
            updatedQuantity
            name
            isRejected
            isUpdated
            customisations {
              _id
              name
              price
              markupPrice
            }
          }
        }
        location {
          row
          seat
          venueName
          venue
          sectionName
          section
          collectionPoint
          collectionPointName
        }
      }
    }
  }
`;

export const GET_ORDER_STATUS_COUNTS = gql`
  query getPatronOrderStatusCounts($payload: PatronOrderFilter!) {
    getPatronOrderStatusCounts(payload: $payload) {
      counts {
        status
        count
      }
    }
  }
`;
export const GET_PATRON_ORDER = gql`
  query getPatronOrder($orderID: String) {
    getPatronOrder(id: $orderID) {
      _id
      orderStatus
      isClickAndCollectEnabled
      serviceMethod
      orderNumber
      createdAt
      updatedAt
      patron {
        _id
        name
        phoneNumber
      }
      location {
        row
        seat
        venue
        venueName
        sectionName
        section
        collectionPoint
        collectionPointName
      }
      vendorOrders {
        _id
        patronOrderID
        orderStatus
        orderNumber
        runner {
          _id
          name
          phoneNumber
        }
        items {
          _id
          name
          count
          updatedQuantity
          isRejected
          isUpdated
          customisations {
            _id
            name
          }
        }
        isOrderUpdated
        vendor {
          _id
          tradingName
          phoneNumber
        }
      }
    }
  }
`;

export const GET_PATRON_ORDER_BY_ORDER_NO = gql`
  query getPatronOrderByOrderNumber($orderNumber: String!) {
    getPatronOrderByOrderNumber(orderNumber: $orderNumber) {
      _id
      orderStatus
      isClickAndCollectEnabled
      serviceMethod
      orderNumber
      createdAt
      updatedAt
      patron {
        _id
        name
        phoneNumber
      }
      location {
        row
        seat
        venue
        venueName
        sectionName
        section
        collectionPoint
        collectionPointName
      }
      vendorOrders {
        _id
        patronOrderID
        orderStatus
        orderNumber
        runner {
          _id
          name
          phoneNumber
        }
        items {
          _id
          name
          count
          updatedQuantity
          isRejected
          isUpdated
          price
          markupPrice
          customisations {
            _id
            name
          }
        }
        isOrderUpdated
        vendor {
          _id
          tradingName
          phoneNumber
        }
      }
    }
  }
`;

export const GET_ITEMS = gql`
  query GetItems($itemQueryParams: ItemQueryParams) {
    getItems(itemQueryParams: $itemQueryParams) {
      _id
      name
      imageUrl
      description
      price
      vendorID
      showInMenu
      outOfStock
    }
  }
`;

export const GET_ALL_NOTIFICATIONS = gql`
  query getAllNotifications($payload: GetAllNotificationsQueryParams!) {
    getAllNotifications(payload: $payload) {
      _id
      subscriberID
      message
      seen
      body
      createdAt
      updatedAt
      type
    }
  }
`;

export const GET_RECONCILIATION_REPORT = gql`
  query getAdminReport(
    $reportType: ReportType!
    $adminId: String!
    $filters: ReportFilterInput!
  ) {
    getAdminReport(
      reportType: $reportType
      adminId: $adminId
      filters: $filters
    )
  }
`;
