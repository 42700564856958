import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import OrderTrackingTab from "../components/order-tracking-tab";
import { OrderManagementTab, OrderStatus, PatronOrderStatus } from "../types";
import { ordersTabConfig } from "../constants/constants";
import { OrderFilter } from "../components/order-filter";
import { useQueryHook } from "../hooks/use-query.hook";
import {
  GET_ALL_PATRON_ORDERS,
  GET_ORDER_STATUS_COUNTS,
  GET_VENUES
} from "../graphql/query";
import { extractAllErrorMessages } from "../utils/graphql";
import { useSnackbar } from "notistack";
import { Loader } from "../elements/loader";
import { OrderContext } from "../context/order-context";
import { filterPatronOrders } from "../utils/utils";
import { PatronOrder } from "../types/order";
import moment from "moment";
import { Text } from "../elements/text";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import SearchBar from "../elements/SearchBar";

const OrdersManagementDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState(1);
  //TODO: should be handled with ref
  const [hasFilterChanged, setHasFilterChanged] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [refreshTime, setRefreshTime] = useState(moment());
  const { enqueueSnackbar } = useSnackbar();
  const orderContext = useContext(OrderContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalOrdersCount, setTotalOrdersCount] = useState<number>()

  const onLoadCompleted = () => {
    setHasFilterChanged(false);
  };

  const refetchOrdersHandler = () => {
    if (!hasFilterChanged) setHasFilterChanged(true);
    refreshCount();
  };

  const onChangeTabSelection = (
    event: React.SyntheticEvent,
    tabIndex: number
  ) => {
    setActiveTab(tabIndex);
  };

  const getFilter = () => {
    return {
      payload: {
        filter: {
          ...orderContext.getFilter(),
          ...(orderContext.vendorIdFilter?.value
            ? { vendorIDs: orderContext.vendorIdFilter.value }
            : {}),
        },
      },
    };
  };

  const { fetch: fetchStatusCounts } = useQueryHook({
    query: GET_ORDER_STATUS_COUNTS,
    variables: { payload: getFilter().payload.filter },
    onCompleted: (data) => {
      assignStatusCounts(data.getPatronOrderStatusCounts.counts);
    },
    onError: (err) => {
      enqueueSnackbar(extractAllErrorMessages(err), {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    },
  });

  const { data: venues = { getVenues: [] } } = useQueryHook({
    query: GET_VENUES
  });

  const { fetch: fetchOrders } = useQueryHook({
    query: GET_ALL_PATRON_ORDERS,
    variables: getFilter(),
    onCompleted: (data) => {
      // recalculateAllCounts(data.getAllPatronOrders.data)
      setTotalOrdersCount(data.getAllPatronOrders.pagination.total)
      fetchStatusCounts({ payload: getFilter().payload.filter });
      setInitialLoading(false);
      onLoadCompleted();
    },
    onError: (err) => {
      enqueueSnackbar(extractAllErrorMessages(err), {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    },
  });

  const assignStatusCounts = (counts: [{ status: string; count: number }]) => {


    ordersTabConfig.forEach((tabInfo) => {
      tabInfo.count = tabInfo.patronOrderStatus
        ? counts.reduce(
          (acc, next) =>
            acc +
            (tabInfo.patronOrderStatus!.includes(
              next.status as PatronOrderStatus
            )
              ? next.count
              : 0),
          0
        )
        : totalOrdersCount;
    });
  };

  const refreshCount = () => {
    setInitialLoading(true);
    fetchOrders(getFilter());
    setRefreshTime(moment());
  };

  useEffect(() => {
    refreshCount();
  }, []);

  useEffect(() => {
    fetchOrders(getFilter());
  }, [activeTab]);

  if (initialLoading) {
    return <Loader visible={true} />;
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2, mt: 1 }}>
        <Grid item xs={12} container sx={{ display: "flex" }}>
          <OrderFilter onFilter={refetchOrdersHandler} />
          <Box display={"flex"} flexDirection={"column"} mr={"18"}>
            <Box display={"flex"} flexDirection={"row"} mr={"18px"}>
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              <IconButton
                onClick={refreshCount}
                style={{ borderRadius: 0 }}
                sx={{
                  justifyContent: "end",
                }}
              >
                <RefreshRoundedIcon
                  sx={{
                    fontSize: "42px",
                    color: "#2A2A2A",
                    cursor: "pointer",
                    padding: "2px",
                    borderRadius: "6px",
                    border: "2px solid #DDE0E4",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    boxShadow: 1,
                  }}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                fontWeight={500}
                fontSize={14}
                color={"#AFA9A9"}
                text={`Last refreshed ${moment(refreshTime).fromNow()}`}
              />
            </Box>
          </Box>
        </Grid>
        <Tabs
          value={activeTab}
          onChange={onChangeTabSelection}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{ height: 48, minHeight: 48 }}
        >
          {ordersTabConfig.map((tab: OrderManagementTab) => {
            return (
              <Tab
                sx={{ minHeight: 40, height: 40, textTransform: "none" }}
                key={`tabs-${tab.index}`}
                icon={<tab.icon />}
                iconPosition="start"
                label={
                  <Typography display={{ xs: "none", sm: "block" }}>
                    {tab.title} ({tab.count})
                  </Typography>
                }
                id={`tabs-${tab.index}`}
              />
            );
          })}
        </Tabs>
      </Box>
      <OrderTrackingTab
        searchQuery={searchQuery}
        shouldReload={hasFilterChanged}
        tabInfo={ordersTabConfig[activeTab]}
        onLoadCompleted={onLoadCompleted}
        venues={venues}
      />
    </>
  );
};

export default OrdersManagementDashboard;
