/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Box, Button, Chip, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { patronOrderStatusConfig } from "../constants/constants";
import { Loader } from "../elements/loader";
import { CustomSelect, MenuItemType } from "../elements/select";
import {
  ASSIGN_RUNNER_FOR_PATRON_ORDER,
  ASSIGN_RUNNER_FOR_VENDOR_ORDER_LIST,
} from "../graphql/mutation";
import { GET_RUNNERS_FOR_ASSIGNMENT, GET_VENUE, GET_VENUES } from "../graphql/query";
import {
  AssignableRunner,
  OrderStatus,
  PatronOrderStatus,
  VendorOrderAssignmentInfo,
  Venue
} from "../types";
import { extractAllErrorMessages } from "../utils/graphql";
import VendorOrderCard from "./vendor-order-card";
import { Text } from "../elements/text";
import parsePhoneNumber from "libphonenumber-js";
import PatronOrderDetailModal from "./patron-order-detail-modal";
import { PatronOrder, ServiceMethod, VendorOrder } from "../types/order";
import { useMutationHook } from "../hooks/use-mutation.hook";
import { useQueryHook } from "../hooks/use-query.hook";

type orderDetailProps = {
  order: PatronOrder;
  refetchOrders: any;
  onSuccessHandler: () => void;
  onCancelHandler: () => void;
  venues: { getVenues: Venue[] };
};

const OrderDetail: React.FC<orderDetailProps> = ({
  order,
  refetchOrders,
  onSuccessHandler,
  onCancelHandler,
  venues,
}) => {
  const [runnerID, setRunnerID] = useState<string>("");

  const [hasPartialAssignment, setHasPartialAssignment] = useState<boolean>(
    order?.orderStatus === PatronOrderStatus.PARTIALLY_ASSIGNED
  );

  const [vendorOrderAssignments, setVendorOrderAssignments] = useState<
    Record<string, string>
  >({});
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    fetchRunner({
      input: {
        venueID: order?.location?.venue,
        sectionIDs: [order?.location?.section],
      },
    })
  }, [order])

  const location = order?.location;
  const serviceMethod = order?.serviceMethod

  const hasPartialOrderPicked = order?.vendorOrders.find(
    (vendorOrder: VendorOrder) => {
      return (
        vendorOrder.orderStatus == OrderStatus.PICKED ||
        vendorOrder.orderStatus == OrderStatus.COMPLETE
      );
    }
  );

  const skipAssignRunner = (): boolean => {
    if (!patronOrderStatusConfig[order.orderStatus].canAssignRunner) {
      return true;
    }

    const preparedOrder = order.vendorOrders.find(
      (order) => order.orderStatus === OrderStatus.PREPARED
    );
    const partiallyAssignedOrder =
      order?.orderStatus === PatronOrderStatus.PARTIALLY_ASSIGNED;
    const assignedOrder = order?.orderStatus === PatronOrderStatus.ASSIGNED;
    if (preparedOrder && order.serviceMethod != "VENDOR_DELIVERY") return false;
    if (partiallyAssignedOrder) return false;
    if (assignedOrder) return false;
    return true;
  };

  const {
    data: runners = { getAssignableRunners: [] },
    loading: runnerLoading,
    fetch: fetchRunner,
  } = useQueryHook({
    query: GET_RUNNERS_FOR_ASSIGNMENT,
    variables: {
      input: {
        venueID: order?.location?.venue,
        sectionIDs: [order?.location?.section],
      },
    },
    onError: (err) => {
      setShowSnackbar(true);
      setErrorMessages(extractAllErrorMessages(err));
    },
    skip: skipAssignRunner(),
  });

  const { mutate: updatePatronOrder, loading: updateLoading } = useMutationHook(
    {
      mutation: ASSIGN_RUNNER_FOR_PATRON_ORDER,
      onCompleted: () => {
        refetchOrders();
        onSuccessHandler();
      },
      onError: (err) => {
        setShowSnackbar(true);
        setErrorMessages(extractAllErrorMessages(err));
      },
    }
  );

  const { mutate: updateVendorOrder, loading: updateVendorOrderLoading } =
    useMutationHook({
      mutation: ASSIGN_RUNNER_FOR_VENDOR_ORDER_LIST,
      onCompleted: () => {
        refetchOrders();
        onSuccessHandler();
      },
      onError: (err) => {
        setShowSnackbar(true);
        setErrorMessages(extractAllErrorMessages(err));
      },
    });

  useEffect(() => {
    fetchRunner({
      input: {
        venueID: order?.location?.venue,
        sectionIDs: [order?.location?.section],
      },
    });
  }, []);

  const runnerSelectData =
    runners?.getAssignableRunners?.map((runnerInfo: AssignableRunner) => {
      const menuItem: MenuItemType = {
        value: runnerInfo.runner._id as string,
        label: `${runnerInfo.runner.name} (${runnerInfo.count})`,
      };
      return menuItem;
    }) ?? [];

  const handleSave = () => {
    if (runnerID) {
      updatePatronOrder({
        variables: {
          id: order?._id,
          runnerID: runnerID,
        },
      });
    }

    if (Object.keys(vendorOrderAssignments).length !== 0) {
      const vendorOrderListInput: VendorOrderAssignmentInfo[] = [];
      Object.keys(vendorOrderAssignments).map((vendorOrderID: string) => {
        vendorOrderListInput.push({
          orderID: vendorOrderID,
          runnerID: vendorOrderAssignments[vendorOrderID],
        });
      });
      updateVendorOrder({
        variables: {
          payload: {
            data: vendorOrderListInput,
          },
        },
      });
    }
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const onRunnerAssignForVendorHandler = (
    vendorOrderID: string,
    runnerID?: string
  ) => {
    let hasVendorAssignment = false;
    if (
      runnerID === null &&
      // eslint-disable-next-line no-prototype-builtins
      vendorOrderAssignments.hasOwnProperty(vendorOrderID)
    ) {
      const vendorOrderAssignmentList: any = vendorOrderAssignments;
      delete vendorOrderAssignmentList[vendorOrderID];
      setVendorOrderAssignments(vendorOrderAssignmentList);
      hasVendorAssignment = vendorOrderAssignmentList.length > 0;
    }
    if (runnerID != null) {
      setVendorOrderAssignments((prevState) => ({
        ...prevState,
        [vendorOrderID]: runnerID,
      }));
      hasVendorAssignment = true;
    }

    if (
      order?.orderStatus !== PatronOrderStatus.PARTIALLY_ASSIGNED &&
      !hasPartialAssignment
    )
      setHasPartialAssignment(hasVendorAssignment);
  };

  if (!order) {
    return (
      <>
        <h1>No Order selected </h1>
      </>
    );
  }

  const createdAtDate = new Date(order.createdAt);
  const date = createdAtDate.toDateString();
  const time = createdAtDate.toLocaleTimeString();

  if (runnerLoading || updateLoading || updateVendorOrderLoading) {
    return <Loader visible={true} />;
  }

  const PatronLocation = () => {
    switch (serviceMethod) {
      case ServiceMethod.DELIVERY:
        return <Text
          text={`Section: ${location?.sectionName}, Row ${location?.row}, Seat ${location?.seat}`}
          fontSize={14}
          fontWeight={600}
        />

      case ServiceMethod.COLLECTION_POINT:
        return <Text
          text={`Section: ${location?.sectionName}, Collection Point: ${location?.collectionPointName}`}
          fontSize={14}
          fontWeight={600}
        />

      default:
        return <></>;
    }
  }

  const venueType = venues?.getVenues?.find(
    (venue: Venue) => venue.id === order?.location?.venue
  )?.venueType;

  const isDineIn =
    (order?.location?.row == "N/A" || "") && order?.location.seat !== "0";

  const isPickUp =
    (order?.location?.row == "N/A" || "") &&
    (order?.location?.seat == "N/A" || "0") &&
    order?.location?.collectionPointName == "";

  let collectionMethodText = " ";

  switch (order.serviceMethod) {
    case ServiceMethod.DELIVERY: {
      collectionMethodText = "DELIVERY";
      break;
    }
    case ServiceMethod.COLLECTION_POINT: {
      collectionMethodText = "COLLECTION POINT DELIVERY";
      break;
    }
    case ServiceMethod.VENDOR_DELIVERY: {
      collectionMethodText = "VENDOR DELIVERY";
      break;
    }
    case ServiceMethod.COLLECT_FROM_VENDOR: {
      if (venueType === "DINE_IN") {
        if (isDineIn) collectionMethodText = "DINE IN";
        else if (isPickUp) collectionMethodText = "PICK UP";
      } else if (venueType === "HOTEL") {
        collectionMethodText = "ROOM SERVICE";
      } else if (venueType === "EVENT") {
        collectionMethodText = "PICK UP";
      }
      break;
    }
    default: {
      collectionMethodText = "";
      break;
    }
  }

  return (
    <>
      {openModal && (
        <PatronOrderDetailModal
          openModal={openModal}
          venues={venues}
          order={order}
          serviceMethod={serviceMethod}
          onClose={() => {
            setOpenModal(false);
          }}
        />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          m: 2,
          mt: 0,
          p: 2,
          maxHeight: "100%",
        }}
        overflow={{ xs: "auto", sm: "unset" }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography fontSize={14} fontWeight={700} color={"#300304"}>
                {`${date}, ${time}`}
              </Typography>
            </Box>
            <Box>
              <Chip
                label={patronOrderStatusConfig[order.orderStatus].text}
                size="small"
                style={{
                  backgroundColor:
                    patronOrderStatusConfig[order.orderStatus].backgroundColor,
                  color: patronOrderStatusConfig[order.orderStatus].textColor,
                  paddingLeft: "4px",
                }}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Typography fontSize={14} fontWeight={500}>
              {`Patron Details: `}
            </Typography>
            <Box
              sx={{
                minHeight: 60,
                backgroundColor: "#F2F4F7",
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                paddingLeft: 1,
              }}
            >
              <Box
                display={"flex"}
                flexWrap={"wrap"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Text
                  text={order.patron?.name}
                  fontSize={14}
                  fontWeight={600}
                />
                <Box
                  sx={{
                    p: 0.5,
                    m: 0.5,
                    mr: 2,
                    borderRadius: 1,
                    backgroundColor: "#344054",
                  }}
                >
                  <Text
                    text={parsePhoneNumber(order?.patron?.phoneNumber || "")
                      ?.formatInternational()
                      .toString()}
                    fontSize={10}
                    fontWeight={200}
                    color={"#F2F2F2"}
                  />
                </Box>
              </Box>
              <PatronLocation />
            </Box>
            <Box>
              <Text
                text={`Venue: ${order.location?.venueName} `}
                fontSize={14}
                fontWeight={600}
                color={"#300304"}
              />
            </Box>
            <Box>
              <Text
                text={`${collectionMethodText} `}
                fontSize={14}
                fontWeight={600}
                color={"#300304"}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1,
            }}
          >
            <Box>
              <Text
                text={`Order: #${order.orderNumber || ""} `}
                fontSize={14}
                fontWeight={600}
                color={"#300304"}
              />
              <Text
                text={`Id: ${order._id || ""} `}
                fontSize={12}
                fontWeight={200}
                color={"#300304"}
              />
            </Box>
            <Button
              variant="outlined"
              sx={{
                py: 0.5,
                fontSize: "12px",
                fontWeight: 500,
                textTransform: "unset",
                color: "#344054",
                borderColor: "#344054",
              }}
              onClick={() => {
                setOpenModal(true);
              }}
            >
              View Order
            </Button>
          </Box>
        </Box>

        {order.orderStatus === PatronOrderStatus.PREPARED &&
          !hasPartialAssignment &&
          runnerSelectData.length > 0 && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ my: 1 }}>
                <CustomSelect
                  required
                  data={runnerSelectData}
                  onChange={setRunnerID}
                  floatingLabel
                  title="Assign runner for entire order"
                  value={runnerID}
                />
              </Box>
              {runnerID === "" && (
                <Box
                  sx={{
                    alignSelf: "center",
                    justifySelf: "center",
                  }}
                >
                  <Text text="OR" fontSize={14} fontWeight={600} />
                </Box>
              )}
            </Box>
          )}
        {order.orderStatus === PatronOrderStatus.ASSIGNED &&
          !hasPartialAssignment &&
          !hasPartialOrderPicked &&
          runnerSelectData.length > 0 && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ my: 1 }}>
                <CustomSelect
                  required
                  data={runnerSelectData}
                  onChange={setRunnerID}
                  floatingLabel
                  title="Re-Assign runner for entire order"
                  value={runnerID}
                />
              </Box>
              {runnerID === "" && (
                <Box
                  sx={{
                    alignSelf: "center",
                    justifySelf: "center",
                  }}
                >
                  <Text text="OR" fontSize={14} fontWeight={600} />
                </Box>
              )}
            </Box>
          )}
        <Box sx={{ felx: 1, pr: 1.5 }} overflow={{ xs: "unset", sm: "auto" }}>
          {order.vendorOrders.map((vendorOrder: VendorOrder, index: any) => {
            return (
              <Box key={`${vendorOrder._id}_${index} `}>
                <VendorOrderCard
                  order={vendorOrder}
                  runners={runners?.getAssignableRunners ?? []}
                  refetchRunner={fetchRunner}
                  isRunnerAssignedAtPatonOrder={runnerID !== ""}
                  onRunnerAssign={onRunnerAssignForVendorHandler}
                />
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          {patronOrderStatusConfig[order.orderStatus].canAssignRunner && (
            <Button
              variant="outlined"
              sx={{
                py: 0.5,
                fontSize: "12px",
                fontWeight: 500,
                textTransform: "unset",
                color: "#344054",
                borderColor: "#344054",
              }}
              disabled={
                !(runnerID || Object.keys(vendorOrderAssignments).length !== 0)
              }
              onClick={handleSave}
            >
              Save
            </Button>
          )}
          <Button
            variant="outlined"
            sx={{
              py: 0.5,
              fontSize: "12px",
              fontWeight: 500,
              textTransform: "unset",
              color: "#344054",
              borderColor: "#344054",
            }}
            onClick={onCancelHandler}
          >
            Cancel
          </Button>
        </Box>
        <Box>
          <Snackbar
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Box>
              {errorMessages.map((err, index) => {
                return (
                  <Box key={err + index} mb={2}>
                    <Alert variant="filled" severity="error">
                      {err}
                    </Alert>
                  </Box>
                );
              })}
            </Box>
          </Snackbar>
        </Box>
      </Box>
    </>
  );
};

export default OrderDetail;
