import { Box, Grid, Typography, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { OrderContext } from "../context/order-context";
import { Loader } from "../elements/loader";
import { GET_ALL_PATRON_ORDERS } from "../graphql/query";
import { customTheme } from "../theme";
import { OrderStatus, PatronOrder } from "../types/order";
import { PatronOrderStatus, OrderManagementTab, Venue } from "../types";
import { extractAllErrorMessages } from "../utils/graphql";
import { filterPatronOrders, sortPatronOrders } from "../utils/utils";
import OrderList from "./order-list";
import { useSnackbar } from "notistack";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { NotificationContext } from "../context/notification-context";
import { useQueryHook } from "../hooks/use-query.hook";
import OrderDetail from "./order-detail";

type OrderTrackingTab = {
  tabInfo: OrderManagementTab;
  onLoadCompleted: () => void;
  searchQuery:string;
  shouldReload: boolean;
  venues: { getVenues: Venue[] };
};

const OrderTrackingTab: React.FC<OrderTrackingTab> = ({
  searchQuery,
  tabInfo,
  onLoadCompleted,
  shouldReload,
  venues
}) => {
  const [selectedOrder, setSelectedOrder] = useState<PatronOrder | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const notificationContext = useContext(NotificationContext);
  const limit = 20;
  const [currentPage, setCurrentPage] = useState<number>(0);
  const orderContext = useContext(OrderContext);
  const [initialLoading, setInitialLoading] = useState(true);
  const [data, setData] = useState({
    getAllPatronOrders: {
      data: [],
      pagination: {
        total: 0,
        limit: 20,
      },
    },
  });


  const getPaginationFilter = () => {
    return {
      pagination: {
        limit: limit,
        pageNumber: currentPage,
      },
      sortBy: {
        name: "createdAt",
        value: -1,
      },
    };
  };

  const getFilter = (orderStatus = tabInfo.patronOrderStatus) => {
    return {
      payload: {
        filter: {
          ...orderContext.getFilter(),
          orderStatus,
          ...(orderContext.vendorIdFilter?.value ? { vendorIDs: orderContext.vendorIdFilter.value } : {}),
        },
        ...getPaginationFilter(),
      },
    };
  };

  const { fetch: fetchOrders, loading: ordersLoading } = useQueryHook({
    query: GET_ALL_PATRON_ORDERS,
    variables: getFilter(),
    onCompleted: (data) => {
      setData(data);
      setInitialLoading(false);
      onLoadCompleted();
    },
    onError: (err) => {
      enqueueSnackbar(extractAllErrorMessages(err), {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    },
  });

  const orders: PatronOrder[] = data?.getAllPatronOrders?.data || [];
  const pagination = data?.getAllPatronOrders?.pagination || {};

  useEffect(()=>{
    const currentOrder = orders.find(order=>order._id === selectedOrder?._id)
    currentOrder && setSelectedOrder(currentOrder)
  }, [orders])

  const filteredOrders: PatronOrder[] = filterPatronOrders(orders, tabInfo,searchQuery)


  // tabInfo.count = filteredOrders.length


  const reloadOrders = () => {
    return fetchOrders(getFilter());
  };

  const onTabInfoChangeHandler = async () => {
    setInitialLoading(true);
    setCurrentPage(0);
    await reloadOrders();
    setSelectedOrder(null);
    setInitialLoading(false);

    if (tabInfo.patronOrderStatus?.includes(PatronOrderStatus.PREPARED)) {
      notificationContext.addSubcribers({
        id: "unassigned-fetch-order",
        condition: (body) => {
          return body.status == OrderStatus.ACCEPTED ||
           body.status == OrderStatus.PREPARING ||
          body.status == OrderStatus.PREPARED
        },
        call: () => {
          fetchOrders(
            getFilter([
              PatronOrderStatus.PREPARED,
              PatronOrderStatus.PREPARING,
              PatronOrderStatus.PARTIALLY_ASSIGNED,
            ])
          );
        },
      });
      return () => {
        notificationContext.clear();
      };
    }else {
      notificationContext.clear();
    }
  };

  useEffect(() => {
    onTabInfoChangeHandler();
  }, [tabInfo]);

  useEffect(() => {
    reloadOrders();
    setSelectedOrder(null);
  }, [currentPage, shouldReload]);

  const onOrderSelectHandler = (order: PatronOrder) => {
    setSelectedOrder(order);
  };

  const refetchOrdersHandler = () => {
    fetchOrders(getFilter());
  };

  const loadNext = () => {
    setCurrentPage(currentPage + 1);
  };
  const loadPrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const isLastPage = () => pagination.total <= (currentPage + 1) * limit;

  if (initialLoading) {
    return <Loader visible={true} />;
  }

  if (filteredOrders && filteredOrders.length === 0) {
    return (
      <Grid item xs={12}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h6">No Orders Available</Typography>
        </Box>
      </Grid>
    );
  }

  return (
    <>
      <Loader visible={ordersLoading} />
      <Grid container spacing={2} height={"calc(100vh - 10.325rem)"}>
        <Grid
          key={"order-list-section"}
          item
          xs={12}
          sm={6}
          display={{ xs: selectedOrder ? "none" : "block", sm: "block" }}
          style={{ minHeight: "100%", maxHeight: "100%" }}
        >
          <Box
            sx={{
              background: customTheme.background,
              maxHeight: "100%",
              height: "100%",
              pb: 1,
              borderRadius: 2,
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <OrderList
              orders={sortPatronOrders(filteredOrders)}
              onSelectOrder={onOrderSelectHandler}
              selectedOrder={selectedOrder?._id}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <IconButton disabled={currentPage === 0}>
                  <ChevronLeft
                    onClick={loadPrev}
                    sx={{ color: currentPage === 0 ? null : "#1976d2" }}
                  />
                </IconButton>
                <Typography>{`${currentPage + 1} / ${Math.ceil(
                  pagination?.total / limit
                )}`}</Typography>

                <IconButton disabled={isLastPage()}>
                  <ChevronRight
                    onClick={loadNext}
                    sx={{ color: isLastPage() ? null : "#1976d2" }}
                  />
                </IconButton>
              </Box>

              <Typography
                sx={{ mr: 5 }}
              >{`Total: ${pagination?.total}`}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          key={"order-detail-section"}
          item
          xs={12}
          sm={6}
          display={{ xs: selectedOrder ? "block" : "none", sm: "block" }}
          style={{ minHeight: "100%", maxHeight: "100%" }}
        >
          <Box
            sx={{
              background: customTheme.background,
              height: "100%",
              py: 2,
              borderRadius: 2,
            }}
          >
            {selectedOrder && (
              <OrderDetail
                key={`${selectedOrder._id}`}
                order={selectedOrder}
                venues={venues}
                refetchOrders={refetchOrdersHandler}
                onSuccessHandler={() => {
                  enqueueSnackbar("order assigned to runner successfully", {
                    variant: "success",
                  });
                  setSelectedOrder(null);
                }}
                onCancelHandler={() => {
                  setSelectedOrder(null);
                }}
              ></OrderDetail>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderTrackingTab;
