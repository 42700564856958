import React from "react";
import { Box, Chip, Divider, Modal, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { patronOrderStatusConfig } from "../constants/constants";
import { Text } from "../elements/text";
import { PatronOrder, ServiceMethod, VendorOrder } from "../types/order";
import VendorOrderDetailCard from "./vendor-order-detail-card";
import { parsePhoneNumber } from "libphonenumber-js";
import { Venue } from "../types";

type PatronOrderDetailModalProps = {
  order: PatronOrder;
  openModal: boolean;
  serviceMethod: string;
  onClose: () => void;
  venues: { getVenues: Venue[] };
};
const PatronOrderDetailModal: React.FC<PatronOrderDetailModalProps> = ({
  order,
  openModal,
  serviceMethod,
  onClose,
  venues
}) => {
  const patron = order?.patron;

  const location = order?.location;
  const isClickAndCollectEnabled = order?.isClickAndCollectEnabled;


  const PatronLocation = () => {

    switch (serviceMethod) {
      case ServiceMethod.DELIVERY:
        return <Text
          text={`Section: ${location?.sectionName}, Row ${location?.row}, Seat ${location?.seat}`}
          fontSize={14}
          fontWeight={600}
        />

      case ServiceMethod.COLLECTION_POINT:
        return <Text
          text={`Section: ${location?.sectionName}, Collection Point: ${location?.collectionPointName}`}
          fontSize={14}
          fontWeight={600}
        />

      default:
        return <></>
    }
  }

  const venueType = venues?.getVenues?.find(
    (venue: Venue) => venue.id === order?.location?.venue
  )?.venueType;

  const isDineIn =
    (order?.location?.row == "N/A" || "") && order?.location.seat !== "0";

  const isPickUp =
    (order?.location?.row == "N/A" || "") &&
    (order?.location?.seat == "N/A" || "0") &&
    order?.location?.collectionPointName == "";

  let collectionMethodText = " ";

  switch (order.serviceMethod) {
    case ServiceMethod.DELIVERY: {
      collectionMethodText = "DELIVERY";
      break;
    }
    case ServiceMethod.COLLECTION_POINT: {
      collectionMethodText = "COLLECTION POINT DELIVERY";
      break;
    }
    case ServiceMethod.VENDOR_DELIVERY: {
      collectionMethodText = "VENDOR DELIVERY";
      break;
    }
    case ServiceMethod.COLLECT_FROM_VENDOR: {
      if (venueType === "DINE_IN") {
        if (isDineIn) collectionMethodText = "DINE IN";
        else if (isPickUp) collectionMethodText = "PICK UP";
      } else if (venueType === "HOTEL") {
        collectionMethodText = "ROOM SERVICE";
      } else if (venueType === "EVENT") {
        collectionMethodText = "PICK UP";
      }
      break;
    }
    default: {
      collectionMethodText = "";
      break;
    }
  }

  return (
    <>
      <Modal
        open={openModal}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // minWidth: 200,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            maxHeight: "80%",
            overflow: "auto",
            p: 4,
          }}
          width={{ sm: 600, xs: "100%" }}
        >
          <Box>
            <Box
              sx={{ display: "flex", mb: 1, justifyContent: "space-between" }}
            >
              <Text
                text={`Order #: ${order.orderNumber || ""}`}
                fontSize={16}
                fontWeight={600}
              />
              <Chip
                label={patronOrderStatusConfig[order.orderStatus].text}
                size="small"
                style={{
                  backgroundColor:
                    patronOrderStatusConfig[order.orderStatus].backgroundColor,
                  color: patronOrderStatusConfig[order.orderStatus].textColor,
                  paddingLeft: "4px",
                }}
              />
            </Box>
            <Divider sx={{ borderColor: "#D0D5DD" }} />
            <Box sx={{ mt: 1 }}>
              <Typography fontSize={14} fontWeight={500}>
                {`Patron Details:`}
              </Typography>
              <Box
                sx={{
                  minHeight: 60,
                  backgroundColor: "#F2F4F7",
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  paddingLeft: 1,
                }}
              >
                <Box
                  display={"flex"}
                  flexWrap={"wrap"}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Text
                    text={order.patron?.name}
                    fontSize={14}
                    fontWeight={600}
                  />
                  <Box
                    sx={{
                      p: 0.5,
                      m: 0.5,
                      mr: 2,
                      borderRadius: 1,
                      backgroundColor: "#344054",
                    }}
                  >
                    <Text
                      text={parsePhoneNumber(patron?.phoneNumber || "")
                        ?.formatInternational()
                        .toString()}
                      fontSize={10}
                      fontWeight={200}
                      color={"#F2F2F2"}
                    />
                  </Box>
                </Box>
                <PatronLocation />
              </Box>
              <Box>
                <Text
                  text={`Venue: ${order.location?.venueName} `}
                  fontSize={14}
                  fontWeight={600}
                  color={"#300304"}
                />
              </Box>
              <Box>
                <Text
                  text={`${collectionMethodText} `}
                  fontSize={14}
                  fontWeight={600}
                  color={"#300304"}
                />
              </Box>
            </Box>
            <Box>
              {order.vendorOrders.map(
                (vendorOrder: VendorOrder, index: number) => {
                  return (
                    <Box
                      key={`vendor-order-detail-${vendorOrder._id}-${index}`}
                    >
                      <VendorOrderDetailCard order={vendorOrder} />
                    </Box>
                  );
                }
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PatronOrderDetailModal;
